import React from "react";
import PropTypes from "prop-types";
import { DynamicField as DFH } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

const Select = ({
  name,
  label,
  value,
  options,
  onChange,
  renderPrint,
  ...restProps
}) => {
  if (renderPrint) {
    const option = options.find((o) => o.name === value);
    return renderPrint(option ? option.label : value);
  }

  const myOptions = options; // .map(x => typeof x === "string" ? {key: x, text: x} : {key: x.name, text: x.label});

  return (
    <DFH
      type="select"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      options={myOptions}
      props={restProps}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderPrint: PropTypes.func,
};

Select.defaultProps = {
  onChange: null,
  value: "",
  renderPrint: null,
  label: "",
};

export default React.memo(Select);
