/** @jsx jsx */
// import { jsx, ThemeProvider } from "theme-ui";
import { jsx } from "theme-ui";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DefaultButton } from "office-ui-fabric-react";
import { selectorSrcmProfile } from "../state/selectors";
import { Login as L } from "../components/Login";
import { Modal } from "../components/Modal";

const SignInButton = ({ btn, btnText, btnStyle = {} }) => {
  const srcmProfile = useSelector(selectorSrcmProfile);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (srcmProfile) {
      setShow(false);
    }
  }, [srcmProfile]);

  const showIt = useCallback(() => setShow(true), []);
  const hideIt = useCallback(() => setShow(false), []);

  if (!srcmProfile) {
    return (
      <div>
        {show && (
          <Modal handleClose={hideIt} showCloseBtn={false}>
            <L />
          </Modal>
        )}
        {btn ? (
          <div onClick={showIt} role="button" onKeyDown={showIt} tabIndex={0}>
            {btn}
          </div>
        ) : (
          <DefaultButton sx={btnStyle} onClick={showIt} text={btnText} />
        )}
      </div>
    );
  }
  return null;
};

SignInButton.propTypes = {
  btn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  btnText: PropTypes.string,
  btnStyle: PropTypes.shape({}),
};

SignInButton.defaultProps = {
  btn: null,
  btnText: "Sign In",
  btnStyle: {},
};

export default SignInButton;
