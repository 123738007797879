import { PhoneNumberUtil } from "google-libphonenumber";

const abhyasiIdRegex = "^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$";
const abhyasiIdTempRegex = "^([HAha]{1}[0-9]{8})$";
const pnrRegex = "^[0-9A-Z]{2}-[A-Z]{4}-[A-Z]{4}$";
const emailRegex =
  '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';

export function validateRE(re1, txt) {
  const re = RegExp(re1);
  return re.test(String(txt));
}

export function validatePhoneNumber(phoneNumber) {
  // if (validateRE(/^\d{10}$/, phoneNumber)) {
  //     // valid 10 digit format
  //     return true
  // }

  let valid = false;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (e) {
    valid = false;
  }
  return valid;
}

export const validateEmail = (t) => validateRE(emailRegex, t);
export const validatePNR = (t) => validateRE(pnrRegex, t);
export const validateAbhyasiId = (t) => validateRE(abhyasiIdRegex, t);
export const validateAbhyasiIdTemp = (t) => validateRE(abhyasiIdTempRegex, t);

const levenshtein = require("js-levenshtein");

function checkForCloseMatch(longString, shortString, allStrings) {
  // too many false positives with very short strings
  if (shortString.length < 3) return "";

  // test if the shortString is in the string (so everything is fine)
  if (longString.split(".").includes(shortString)) return "";

  const parts = longString.split(".");

  for (let i = 0; i < parts.length; i += 1) {
    const l = levenshtein(parts[i], shortString);
    if (l <= 1 && !allStrings.includes(parts[i])) {
      const newp = [...parts];
      newp[i] = shortString;
      return newp.join(".");
    }
  }
  return "";
}

export function checkForDomainTypo(userEmail) {
  const domains = [
    "gmail",
    "ymail",
    "mail",
    "rocketmail",
    "hotmail",
    "outlook",
    "yahoo",
    "icloud",
    "zoho",
    "yandex",
    "aol",
  ];
  const [leftPart, rightPart] = userEmail.toLowerCase().split("@");

  for (let i = 0; i < domains.length; i += 1) {
    const domain = domains[i];

    const result = checkForCloseMatch(rightPart, domain, domains);

    if (result) return `${leftPart}@${result}`;
  }

  return "";
}
