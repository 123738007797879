export const SHOW_ALERT = "SHOW_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";

export const SET_USER = "SET_USER";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const UNSET_USER = "UNSET_USER";
// export const SET_GLOBAL_CONFIG = "SET_GLOBAL_CONFIG";

export const INVALIDATE_URL = "INVALIDATE_URL";
export const REQUEST_URL = "REQUEST_URL";
export const RECEIVE_URL = "RECEIVE_URL";

export const SET_GLOBALS = "SET_GLOBALS";
export const SET_PERSIST = "SET_PERSIST";

export const INIT_SRCM_COLLECTION = "INIT_SRCM_COLLECTION";
export const DESTROY_SRCM_COLLECTION = "DESTROY_SRCM_COLLECTION";
export const INVALIDATE_SRCM_API_STORE = "INVALIDATE_SRCM_API_STORE";
export const REQUEST_SRCM_API_STORE = "REQUEST_SRCM_API_STORE";
export const RECEIVE_SRCM_API_STORE = "RECEIVE_SRCM_API_STORE";
export const RECEIVE_SRCM_API_STORE_DOC = "RECEIVE_SRCM_API_STORE_DOC";
