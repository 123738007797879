import get1 from "lodash/get";
import set1 from "lodash/set";
import uniq1 from "lodash/uniq";
import noop1 from "lodash/noop";
import has1 from "lodash/has";
import omit1 from "lodash/omit";
import memoize1 from "lodash/memoize";
import compact1 from "lodash/compact";
import upperFirst1 from "lodash/upperFirst";
import pickBy1 from "lodash/pickBy";
import trim1 from "lodash/trim";
import max1 from "lodash/max";
import debounce1 from "lodash/debounce";
import startCase1 from "lodash/startCase";
import kebabCase1 from "lodash/kebabCase";
import sortBy1 from "lodash/sortBy";
import lowerCase1 from "lodash/lowerCase";
import truncate1 from "lodash/truncate";
import includes1 from "lodash/includes";
import orderBy1 from "lodash/orderBy";
import flatten1 from "lodash/flatten";
import groupBy1 from "lodash/groupBy";

export const get = get1;
export const set = set1;
export const uniq = uniq1;
export const noop = noop1;
export const has = has1;
export const omit = omit1;
export const memoize = memoize1;
export const upperFirst = upperFirst1;
export const compact = compact1;
export const pickBy = pickBy1;
export const trim = trim1;
export const max = max1;
export const debounce = debounce1;
export const startCase = startCase1;
export const kebabCase = kebabCase1;
export const sortBy = sortBy1;
export const orderBy = orderBy1;
export const lowerCase = lowerCase1;
export const truncate = truncate1;
export const includes = includes1;
export const flatten = flatten1;
export const groupBy = groupBy1;

export const titleCase = (n) => {
  try {
    return startCase1(n.toLowerCase());
  } catch (e) {
    return n;
  }
};
