import { SHOW_ALERT } from "../constants";

export default ({
  message,
  confirm_text = "",
  confirm_callback = "",
  cancel_text = "OK",
  title = "Alert",
  is_blocking = false,
}) => ({
  type: SHOW_ALERT,
  alert: {
    message,
    confirmText: confirm_text,
    confirmCallback: confirm_callback,
    cancelText: cancel_text,
    isBlocking: is_blocking,
    title,
  },
});
