import { DefaultButton } from "office-ui-fabric-react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { dismissAlert } from "../state/actions";
import { selectorAlert } from "../state/selectors";
import { Modal } from "./Modal";

export const Alert = React.memo(() => {
  const alert = useSelector(selectorAlert);
  const dispatch = useDispatch();

  if (alert === null || alert === undefined) {
    return null;
  }

  if (!alert.message) {
    return null;
  }
  const {
    title = "",
    cancelText = "OK",
    confirmText = null,
    confirmCallback = null,
    cancelCallback = () => {
      dispatch(dismissAlert());
    },
    isBlocking = false,
    message,
  } = alert;

  // console.log("Alert", alert);

  return (
    <Modal handleClose={isBlocking ? null : cancelCallback} title={title}>
      <div style={{ padding: "10px 0" }}>{message}</div>
      <div style={{ float: "right" }}>
        {!!confirmText && !!confirmCallback && (
          <DefaultButton
            onClick={() => {
              cancelCallback();
              confirmCallback();
            }}
            text={confirmText}
          />
        )}
        <DefaultButton onClick={cancelCallback} text={cancelText} />
      </div>
    </Modal>
  );
});
