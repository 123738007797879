/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import { DefaultButton } from "office-ui-fabric-react";
import { Modal } from "../components/Modal";
import TheAvatar from "./TheAvatar";
import ensureSignedInSilent from "./ensureSignedInSilent";
import SignOutButton from "./SignOutButton";
import useAuth from "./useAuth";

const colorKeys = [
  "background",
  "text",
  "primary",
  "secondary",
  "errortext",
  "errorbg",
  "muted",
  "highlight",
  "background2",
];

const decodeColors = (colorString) => {
  const ret = {};
  colorString
    .split(":")
    .filter((x, xidx) => xidx < colorKeys.length)
    .forEach((x, xidx) => {
      ret[colorKeys[xidx]] = `#${x}`;
    });
  return ret;
};

const colors = decodeColors(
  `ffffff:000000:228b22:006400:ba0000:fed4d4:f6f6f6:efeffe:f0f0f0`
);
const Avatar = ensureSignedInSilent(({ avatarSize = 36 }) => {
  const { srcmProfile } = useAuth();
  const { photoUrl, name, isPrefect, email, mobile } = srcmProfile;
  const initials = name.substr(0, 1).replace(" ", "@");

  const [show, { setTrue, setFalse }] = useMyBoolean(false);

  const [showProfile, { toggle: toggleProfile }] = useMyBoolean(false);

  return (
    <ThemeProvider theme={{ colors }}>
      <div>
        {/* <SafeImage onClick={() => setShow(true)} src={photoUrl} alt={initials}
          style={{
            borderRadius: "50%",
            height: `${size}px`, width: `${size}px`, fontSize: `${size}px`,
            color: "white", backgroundColor, cursor: "pointer"
          }}
        /> */}
        <div onKeyDown={setTrue} onClick={setTrue} role="button" tabIndex="0">
          <TheAvatar src={photoUrl} initials={initials} size={avatarSize} />
        </div>
        {show && (
          <Modal
            handleClose={setFalse}
            showCloseBtn={false}
            position={showProfile ? "right" : "top-right"}
          >
            {!showProfile && (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <TheAvatar src={photoUrl} initials={initials} size={54} />
                      {/* <SafeImage src={photoUrl} alt={initials}
                    style={{
                      borderRadius: "50%",
                      height: `${60}px`, width: `${60}px`, fontSize: `${60}px`,
                      color: "white", backgroundColor: "grey"
                    }
                    }
                  /> */}
                    </td>
                    <td style={{ paddingLeft: "10px" }}>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "black",
                        }}
                      >
                        {name}
                      </div>
                      <div style={{ fontSize: "14px", color: "black" }}>
                        {email}
                      </div>
                      <div style={{ fontSize: "14px", color: "black" }}>
                        {mobile}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td style={{ paddingLeft: "10px" }}>
                      <div style={{ fontSize: "12px", color: "black" }}>
                        {isPrefect ? "Preceptor" : ""}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingTop: "10px" }}>
                      <DefaultButton
                        onClick={toggleProfile}
                        text="My Profile"
                      />
                      <SignOutButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Modal>
        )}
      </div>
    </ThemeProvider>
  );
});

export default Avatar;
