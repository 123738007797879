import fetchSrcmAPI from "./fetchSrcmAPI";

export default function fetchSrcmProfileMe(authToken) {
  return fetchSrcmAPI("/api/v2/me/", authToken).then((res) => res.results[0]);
}

export function patchSrcmProfileMe(authToken, id, methodParams) {
  return fetchSrcmAPI(
    `/api/v2/me/${id}/`,
    authToken,

    "PATCH",
    {},
    {},
    true,
    methodParams,
    "baseClient"
  ).then((res) => res);
}
