import React from "react";
import PropTypes from "prop-types";
import { Input, Flex } from "theme-ui";
import FontAwesomeIcon from "../Icon";
import "../../fontawesome";

const InputWithIconLayout = React.forwardRef(
  ({ inputField, flexWrapper, iconWrapperStyles, faIcon }, ref) => {
    return (
      <Flex {...flexWrapper}>
        <Input {...inputField} ref={ref} />
        <Flex sx={iconWrapperStyles}>
          <FontAwesomeIcon {...faIcon} />
        </Flex>
      </Flex>
    );
  }
);

InputWithIconLayout.propTypes = {
  flexWrapper: PropTypes.shape({}).isRequired,
  inputField: PropTypes.shape({}).isRequired,
  faIcon: PropTypes.shape({}).isRequired,
  iconWrapperStyles: PropTypes.shape({}).isRequired,
};

const InputWithIcon1 = React.forwardRef(
  ({ onFocus, onChange, onBlur, variant, icon, value, ...restProps }, ref) => {
    const ref1 = React.useRef(null);
    const inputWithIcon = {
      ref: ref || ref1,
      inputField: {
        variant,
        value,
        onFocus,
        onBlur,
        onChange,
        ...restProps,
      },
      flexWrapper: {
        sx: React.useMemo(
          () => ({
            justifyContent: "center",
            bg: "background",
            width: "100%",
            fontSize: "20px",
          }),
          []
        ),
      },
      iconWrapperStyles: React.useMemo(() => {
        return variant === "border-bottom"
          ? {
              borderBottom: "1px solid transparent",
              borderBottomColor: "primary",
              pr: 1,
            }
          : {};
      }, [variant]),
      faIcon: {
        variant: "icons.variant1",
        icon,
      },
    };

    return <InputWithIconLayout {...inputWithIcon} />;
  }
);

const InputWithIcon = (props) => {
  const { type, renderPrint, value } = props;
  if (renderPrint) {
    let v = value;
    if (type === "date" && v) {
      v = new Date(v).toString().substring(0, 15);
    }
    return renderPrint(v);
  }
  return (
    <InputWithIcon1
      type={
        type === "abhyasi_ref" || type === "abhyasi_ref_temp" ? "text" : type
      }
      {...props}
    />
  );
};
InputWithIcon.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  renderPrint: PropTypes.func,
};

InputWithIcon.defaultProps = {
  value: "",
  renderPrint: null,
  type: "text",
};
InputWithIcon1.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(["border-0", "border-bottom", "primary"]),
};

InputWithIcon1.defaultProps = {
  type: "text",
  variant: "border-0",
  value: "",
  onFocus: null,
  onChange: null,
  onBlur: null,
  icon: "",
};

export default React.memo(InputWithIcon);
