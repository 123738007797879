import { get, set } from "sites-common/utils/lodash";

import {
  SHOW_ALERT,
  DISMISS_ALERT,

  // SET_GLOBAL_CONFIG,
  INVALIDATE_URL,
  REQUEST_URL,
  RECEIVE_URL,
  SET_GLOBALS,
  INIT_SRCM_COLLECTION,
  DESTROY_SRCM_COLLECTION,
  INVALIDATE_SRCM_API_STORE,
  REQUEST_SRCM_API_STORE,
  RECEIVE_SRCM_API_STORE,
  RECEIVE_SRCM_API_STORE_DOC,
} from "./constants";

const initialState = {
  alert: null,
  json: {},
  globals: {},
  globalConfig: {},
};

export const initUrlBlob = {
  isLoaded: false,
  isFetching: false,
  errorMessage: "",
  data: null,
};

function url(state = initUrlBlob, action) {
  switch (action.type) {
    case INVALIDATE_URL:
      return { ...state, isFetching: false, errorMessage: action.error };
    case REQUEST_URL:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case RECEIVE_URL:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        data: action.data,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export const globalReducers = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: null,
      };

    case INVALIDATE_URL:
    case RECEIVE_URL:
    case REQUEST_URL:
      return {
        ...state,
        json: {
          ...state.json,
          [action.key]: url(
            get(state, ["json", action.key], initUrlBlob),
            action
          ),
        },
      };

    // case SET_GLOBAL_CONFIG:
    //   return {
    //     ...state,
    //     globalConfig: action.value,
    //   };

    case SET_GLOBALS:
      if (typeof action.key === "string" && !action.key.includes(".")) {
        return {
          ...state,
          globals: {
            ...state.globals,
            [action.key]: action.value,
          },
        };
      }
      const r = {
        ...state,
        globals: {
          ...state.globals,
        },
      };
      set(r.globals, action.key, action.value);
      return r;

    default:
      return state;
  }
};

const initialSrcmCollection = {
  apiParams: {},
  data: {},
  ordered: [],
  count: 0,
};
const srcmcollection = (state = initialSrcmCollection, action) => {
  let count;
  let data;
  let ordered;
  switch (action.type) {
    case RECEIVE_SRCM_API_STORE_DOC:
      data = { ...state.data };
      const r = action.data;
      data[action.docId] = { ...get(state, ["data", action.docId], {}), ...r };
      ordered = Object.values(data);
      count = Math.max(state.count, ordered.length);
      return { ...state, data, ordered, count };

    case RECEIVE_SRCM_API_STORE:
      data = { ...state.data };
      const { results } = action.data;
      const { primaryKey = "id" } = action.apiParams;
      results.forEach((res) => {
        data[`${res[primaryKey]}`] = {
          ...state.data[`${res[primaryKey]}`],
          ...res,
        };
      });
      count = action.data.count;
      ordered = Object.values(data);
      return { ...state, data, ordered, count };

    default:
      return state;
  }
};

function apicall(state = initUrlBlob, action) {
  switch (action.type) {
    case INVALIDATE_SRCM_API_STORE:
      return { ...state, errorMessage: action.error };
    case REQUEST_SRCM_API_STORE:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case RECEIVE_SRCM_API_STORE:
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        errorMessage: "",
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}
const initialSrcmState = {
  api_calls: {},
  collections: {},
};
export const srcmReducers = (state = initialSrcmState, action) => {
  switch (action.type) {
    case RECEIVE_SRCM_API_STORE:
      return {
        collections: {
          ...state.collections,
          [action.collection]: srcmcollection(
            state.collections[action.collection],
            action
          ),
        },
        api_calls: {
          ...state.api_calls,
          [action.key]: apicall(
            get(state, ["api_calls", action.key], initUrlBlob),
            action
          ),
        },
      };
    case RECEIVE_SRCM_API_STORE_DOC:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.collection]: srcmcollection(
            state.collections[action.collection],
            action
          ),
        },
      };
    case INVALIDATE_SRCM_API_STORE:
    case REQUEST_SRCM_API_STORE:
      return {
        ...state,
        api_calls: {
          ...state.api_calls,
          [action.key]: apicall(
            get(state, ["api_calls", action.key], initUrlBlob),
            action
          ),
        },
      };

    case INIT_SRCM_COLLECTION:
      return {
        ...state,
        collections: {
          // ignore initing collection again if it is already available in state
          [action.collection]: {
            ...initialSrcmCollection,
            apiParams: action.apiParams,
          },
          ...state.collections,
        },
      };

    case DESTROY_SRCM_COLLECTION:
      const n = { ...state.collections };
      delete n[action.collection];
      return { ...state, collections: n };

    default:
      return state;
  }
};
