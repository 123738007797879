/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import Avatar from "gatsby-plugin-hfn-profile/auth/Avatar";
import SignInButton from "gatsby-plugin-hfn-profile/auth/SignInButton";
import { navigate } from "gatsby";

const goHome = () => navigate("/");

const Header = () => {
  return (
    <Box>
      <Flex sx={{ bg: "muted" }}>
        <Box px={2} sx={{ py: 2, flex: "1 1 auto" }}>
          <div
            role="button"
            tabIndex="0"
            sx={{ cursor: "pointer", fontSize: "2em" }}
            onClick={goHome}
            onKeyDown={goHome}
          >
            Heartfulness
          </div>
        </Box>
        <Box p={2}>
          <Avatar />
          <SignInButton btnText="Sign-In" />
        </Box>
      </Flex>
    </Box>
  );
};

const accessControl = {
  msgUnAuthorized: (r) => `Hello, ${r.name}`,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r?.abhyasiId,
};

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
