import { SET_PERSIST } from "../constants";

export default function setPersist(key, value, subtype = "") {
  return {
    type: SET_PERSIST,
    key,
    value,
    subtype,
  };
}
