import React from "react";
import { v4 as uuidv4 } from "uuid";
// import { fetchSrcmAPI } from '../../../lib/fetchSrcmAPI';
import PropTypes from "prop-types";
import { memoize } from "../../../utils/lodash";
import { displayError } from "../../../utils/customErrors";
import {
  profileApiCachedUrl,
  profileApiUrl,
} from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";

const getMandatoryEnv = require("../../../utils/getMandatoryEnv");

const { cloudFunctions } = getMandatoryEnv(["cloudFunctions"]);

// const postGeoSearchPromise = ( formatted_address, google_place_id) =>
//     fetchSrcmAPI(
//         "cities/geosearch",
//         "",
//         "POST",
//         { "accept": "application/json", "Content-Type": "application/json" },
//         {},
//         false,
//         { formatted_address, google_place_id }
//     )

// const postGeoSearch = (async ( formatted_address, google_place_id) => {
//     let ret = [];
//     let response = await postGeoSearchPromise( formatted_address, google_place_id);
//     let data = response;

//     if (data.city_id) {
//         let cityresponse = await fetchCity(data.city_id);
//         ret.push(cityresponse)
//     }
//     // console.log("data", data, ret);
//     return ret;
// })

// const processAllPredictions = async (predictions, noStrictId) => {
//     let resp = [];
//     for await (let p of predictions) {
//         let { description, place_id, terms } = p;

//         if (noStrictId) {
//             let intermediateResult = (
//                 {
//                     id: [terms[0].value, terms[1].value, get(terms, [2, "value"], terms[1].value)].join("~"),
//                     name: terms[0].value,
//                     state: terms[1].value,
//                     country: get(terms, [2, "value"], terms[1].value),
//                     description, place_id,
//                 }
//             );
//             postGeoSearchPromise( description, place_id);
//             resp.push(intermediateResult)
//         } else {
//             let [newret] = await postGeoSearch( description, place_id);
//             resp.push(newret)
//         }
//     }
//     return resp;
// }

const getGooglePlaces = async (
  sessiontoken,
  input
  // noStrictId
) => {
  const { gPlacesApi } = cloudFunctions;
  // console.log(gPlacesApi, input);
  if (!gPlacesApi) {
    return [];
  }
  const places = await fetch(
    `${gPlacesApi}?session=${sessiontoken}&input=${input}`
  )
    .then((res) => res.json())
    // .then(( predictions ) => processAllPredictions(predictions, noStrictId))
    .catch(displayError);
  if (Array.isArray(places)) {
    return places;
  }
  // console.log(places);

  return [];
};

const fetchCitySrcm = async (cityId) => {
  const r = await fetch(profileApiUrl("cities-id", cityId))
    .then((R) => R.json())
    .then((R) => ({
      id: R.id,
      name: R.name,
      country: R.country.name,
      state: R.state ? R.state.name : R.country.name,
      country_id: R.country.id,
      state_id: R.state ? R.state.id : R.country.id,
    }));
  // console.log("RRRE", r);
  return r;
};

export const fetchCity = (cityId) => {
  // console.log("fetchCity", cityId);
  if (typeof cityId === "string") {
    if (cityId.includes("~")) {
      const s = cityId.split("~");
      const o = { id: cityId, name: s[0], state: s[1], country: s[2] };
      return Promise.resolve(o);
    }
    if (cityId.endsWith("^")) {
      const s = cityId.split("^");
      const o = { id: cityId, name: s[0], state: "", country: "" };
      return Promise.resolve(o);
    }
  }
  // console.log("calling fetchCity", cityId);

  return fetch(profileApiCachedUrl("cities-id", cityId))
    .then((R) => R.json())
    .catch(() => fetchCitySrcm(cityId));
};

const dflt_in = [
  {
    id: 147,
    name: "Hyderabad",
    state: "Telangana",
    country: "India",
    country_id: 358,
    state_id: 3535,
  },
  {
    id: 704,
    name: "Chennai",
    state: "Tamil Nadu",
    country: "India",
    country_id: 358,
    state_id: 4313,
  },
  {
    id: 33679,
    name: "Bengaluru",
    state: "Karnataka",
    country: "India",
    country_id: 358,
    state_id: 4312,
  },
  {
    id: 448,
    name: "Mumbai",
    state: "Maharashtra",
    country: "India",
    country_id: 358,
    state_id: 3734,
  },
  {
    id: 255,
    name: "New Delhi",
    state: "Delhi",
    country: "India",
    country_id: 358,
    state_id: 2151,
  },
  {
    id: 889,
    name: "Kolkata",
    state: "West Bengal",
    country: "India",
    country_id: 358,
    state_id: 4314,
  },
];

function renderSuggestion(suggestion) {
  return (
    <div>
      <div>{suggestion.name}</div>
      <div style={{ fontSize: "0.8em", opacity: 0.5 }}>
        {!suggestion.country ? (
          <div sx={{ mt: 1 }}>click to select this city </div>
        ) : (
          <div>
            {suggestion.state}
            {suggestion.state && ", "}
            {suggestion.country}
          </div>
        )}
      </div>
    </div>
  );
}

const getConfigs = memoize(
  ({ zq, country, includeOnly, noStrictId, allowNewCities }) => {
    const sessiontoken = uuidv4();
    const config = {
      dflt:
        zq !== "in"
          ? []
          : (includeOnly ? dflt_in.filter(includeOnly) : dflt_in).filter(
              (r) => !country || country === r.country
            ),

      fetch: fetchCity,

      // Trigger suggestions
      getSuggestionValue: (suggestion) =>
        suggestion.name +
        (suggestion.state ? `, ${suggestion.state}` : "") +
        (suggestion.country ? `, ${suggestion.country}` : ""),
      // Render Each Option
      renderSuggestion,
      api: (itrimValue) =>
        country
          ? profileApiCachedUrl(
              "cities-country-prefix",
              country,
              itrimValue.substring(0, 1)
            )
          : profileApiCachedUrl("cities-prefix", itrimValue.substring(0, 1)),
      data2resultsAsync: async (data, itrimValue, getSuggestionValue, dflt) => {
        const dfltIds = dflt.map((r) => r.id);
        const filterFn = (f, itrimValue1) =>
          getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);

        const cur_results = data.results
          .filter(
            (f) =>
              (!includeOnly || includeOnly(f)) &&
              f.active &&
              filterFn(f, itrimValue) &&
              !dfltIds.includes(f.id)
          )
          .filter((i, idx) => idx < 20);

        if (!!itrimValue && cur_results.length === 0) {
          // let r = await runGeoSearch(itrimValue);
          if (allowNewCities) {
            return [
              {
                id: `${itrimValue}^^`,
                name: itrimValue,
                state: "",
                country: "",
              },
            ];
          }
          const r = await getGooglePlaces(sessiontoken, itrimValue, noStrictId);
          return r;
        }
        const dflt_results = dflt.filter((f) => filterFn(f, itrimValue));
        return [...dflt_results, ...cur_results];
      },
    };

    return config;
  }
);

const City = ({
  zq,
  country,
  includeOnly,
  allowNewCities = false,
  noStrictId = false,
  ...restprops
}) => {
  const config = getConfigs({
    zq,
    country,
    includeOnly,

    noStrictId,
    allowNewCities: allowNewCities && !cloudFunctions.gPlacesApi,
  });

  // if (m1.gPlacesApi) {
  // this handles state country by default
  // for new cities, fallback on google will ensure the state and country
  // }

  return <ServerAutoSuggest {...restprops} config={config} />;
};

City.propTypes = {
  zq: PropTypes.string,
  country: PropTypes.string,
  includeOnly: PropTypes.func,
  allowNewCities: PropTypes.bool,
  noStrictId: PropTypes.bool,
};

City.defaultProps = {
  zq: "",
  country: "",
  includeOnly: null,
  allowNewCities: false,
  noStrictId: false,
};

export default City;
