import profileApiUrl from "./profileApiUrl";
import { mySrcmConfig } from "./common-utils";
import { get } from "../../utils/lodash";

export default function fetchSrcmAPIDecompose(
  api,
  authToken = "",
  method = "GET",
  extraHdrs = {},
  extraData = {},
  forceAuth = false,
  methodParams = {},
  client = "baseClient"
) {
  let xApi = api;
  let xMethod = method;
  let xHeaders = extraHdrs;

  if (api === "get-token") {
    xApi = "secondary-firebase-token";
    xMethod = "POST";
    xHeaders = {};
  } else if (api === "get-token-primary") {
    xApi = "primary-firebase-token";
    xMethod = "POST";
    xHeaders = {};
  } else {
    xApi = api;
  }
  const xData = {
    ...extraData,
    ...(xMethod === "GET" ? {} : { body: JSON.stringify(methodParams) }),
  };
  const xUrl = profileApiUrl(xApi);

  let url = "";
  if (
    typeof methodParams === "object" &&
    Object.keys(methodParams).length > 0 &&
    xMethod === "GET"
  ) {
    url = xUrl.includes("?")
      ? `${xUrl}&${new URLSearchParams(methodParams)}`
      : `${xUrl}?${new URLSearchParams(methodParams)}`;
  } else {
    url = xUrl;
  }

  const apicore = xApi.split("/")[3];
  let headers;
  let data;
  if (
    ["groups", "cities", "states", "countries"].includes(apicore) &&
    !forceAuth
  ) {
    headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      ...xHeaders,
    };
    data = {
      method: xMethod,
      headers,
      ...xData,
    };
    return [url, data, "json"];
  }
  headers = {
    Authorization: `Bearer ${authToken}`,
    "x-client-id": get(mySrcmConfig, client, mySrcmConfig.baseClient),
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    ...xHeaders,
  };
  data = {
    method: xMethod,
    headers,
    ...xData,
  };
  if (
    apicore === "secondary-firebase-token" ||
    apicore === "primary-firebase-token" ||
    xApi === "/api/v2/me/change_password/" ||
    (xApi.startsWith("/api/v2/me/approvals/") && xMethod === "POST")
  ) {
    return [url, data, "text"];
  }
  return [url, data, "json"];
}
