export const buttonStyles = {
  danger: {
    root: {
      backgroundColor: "#dc3545",
      width: "auto",
      height: "auto",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#bd2130", color: "white" },
  },
  dangerMedium: {
    root: { backgroundColor: "#FFCCCB", marginTop: 6 },
    rootHovered: { backgroundColor: "red", color: "white" },
  },
  dangerLight: {
    root: {
      backgroundColor: "#FFCCCB",
      width: "auto",
      height: "auto",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "red", color: "white" },
  },
  warning: {
    root: {
      backgroundColor: "#ffc107",
      width: "auto",
      height: "auto",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#d39e00", color: "white" },
  },
  warningMedium: {
    root: { backgroundColor: "#ffc107", color: "black", marginTop: 10 },
    rootHovered: { backgroundColor: "#d39e00", color: "black" },
  },
  warningLight: {
    root: {
      backgroundColor: "#ffd1b7",
      width: "auto",
      height: "auto",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#ffc107", color: "white" },
  },
  blue: {
    root: {
      width: "auto",
      height: "auto",
      backgroundColor: "#0062cc",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#0056b3", color: "white" },
  },
  blueMedium: {
    root: { backgroundColor: "#add8e6", color: "black" },
    rootHovered: { backgroundColor: "#0062cc", color: "white" },
  },
  blueLight: {
    root: {
      width: "auto",
      height: "auto",
      backgroundColor: "#2196f3",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#0062cc", color: "white" },
  },
  greenLight: {
    root: {
      width: "auto",
      height: "auto",
      backgroundColor: "#3ded97",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "#ffffff",
      borderRadius: "30px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
      },
    },
    rootHovered: { backgroundColor: "#234f1e", color: "white" },
  },
  default: {
    root: {
      width: "auto",
      height: "auto",
      backgroundColor: "#dddddd",
      padding: "8px 18px",
      fontSize: "14px",
      border: "0!important",
      color: "black",
      borderRadius: "30px",
      marginLeft: "10px",
      "@media (max-width: 480px)": {
        padding: "7px 14px",
        fontSize: "12px",
        "span i": {
          fontSize: "12px",
        },
      },
    },
    rootHovered: { backgroundColor: "#cccccc", color: "black" },
  },
};

export const inputStyles = {
  dialogWidth: {
    maxWidth: " 100%",
    width: "600px",
    maxHeight: "100%",
    height: "600px",
  },
  inputStyle: {
    width: "100%",
    padding: ".375rem .75rem",
    borderRadius: ".25rem",
  },
  errorMessage: { color: "red", fontSize: "14px" },
  successMessage: { color: "#008000" },
  inputError: { border: "1px solid red" },
  required: { color: "red" },
};

export const containerStyles = {
  mobileOptimized: {
    p: 2,
    "@media (max-width: 480px)": {
      width: "100%",
    },
    "@media (min-width: 481px)": {
      width: "480px",
      margin: "0 auto",
    },
  },
  fullPage: {
    width: "100%",
  },
  fixedPage: {
    p: 2,
    "@media (max-width: 575px)": {
      width: "100%",
    },
    "@media (min-width: 576px) and (max-width:767px)": {
      width: "540px",
      margin: "0 auto",
    },
    "@media (min-width: 768px) and (max-width:991px)": {
      width: "720px",
      margin: "0 auto",
    },
    "@media (min-width: 992px) and (max-width:1199px)": {
      width: "960px",
      margin: "0 auto",
    },
    "@media (min-width: 1200px)": {
      width: "1140px",
      margin: "0 auto",
    },
  },
  middle: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    padding: "8px",
  },
  centered: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  flexcenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const dialogStyles = {
  small: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 300,
          minWidth: 300,
          maxWidth: 300,
        },
      },
    },
  },
  medium: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 600,
          minWidth: 600,
          maxWidth: 600,
        },
      },
    },
  },
  mediumX: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 850,
          minWidth: 850,
          maxWidth: 850,
        },
      },
    },
  },
  large: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: "96%",
          maxWidth: 1200,
        },
        "@media (max-width :580px)": {
          ".scrollableContent-85": {
            maxHeight: "72vh",
          },
        },
      },
    },
  },
};

export const fileUploadStyles = {
  uploadContainer: {
    position: "relative",
    margin: "25px 0 15px",
    border: "2px dotted lightgray",
    padding: "35px 20px",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  label: {
    top: "-21px",
    fontSize: "13px",
    color: "black",
    left: 0,
    position: "absolute",
  },
  formField: {
    fontSize: "18px",
    display: "block",
    width: "100%",
    border: "none",
    textTransform: "none",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,

    "&:focus": {
      outline: "none",
    },
  },
  filePreviewContainer: {
    marginBottom: "35px",

    span: {
      fontSize: "14px",
    },
  },
  previewList: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    "@media only screen and (max-width: 400px)": {
      flexDirection: "column",
    },
  },
  previewContainer: {
    padding: "0.25rem",
    width: "20%",
    height: "120px",
    borderRadius: "6px",
    boxSizing: "border-box",

    "& > div:first-of-type": {
      height: "100%",
      position: "relative",
    },
  },
  imgPreview: {
    borderRadius: "6px",
    width: "100%",
    height: "100%",
  },
};

const fabricStyles = {
  buttonStyles,
  inputStyles,
  containerStyles,
  fileUploadStyles,
};

export default fabricStyles;
