
  const { combineReducers, createStore, applyMiddleware } = require("redux");

 const thunkMiddleware = require("redux-thunk").default;
  let loggerMiddleware = null;

  if (process.env.NODE_ENV === "development") {
      const { createLogger } = require("redux-logger");
      loggerMiddleware = createLogger();
    }
const root = require("../state/mainReducers").default;
const app = require("/home/runner/work/me-widgets/me-widgets/sites/widgets/src/state/appReducer").default; 

const r = combineReducers({...root , app
});

// const { persistStore } = require('redux-persist')


module.exports = (preloadedState={}) => {
  try{
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(r, preloadedState, process.env.NODE_ENV === "development" ? composeEnhancer(applyMiddleware(thunkMiddleware, loggerMiddleware)) : applyMiddleware(thunkMiddleware));
    // const persistor = persistStore(store);
    return { store, /* persistor */ }
  } catch(e) {
    const store = createStore(r, preloadedState, process.env.NODE_ENV === "development" ? applyMiddleware(thunkMiddleware, loggerMiddleware) : applyMiddleware(thunkMiddleware));
    // const persistor = persistStore(store);
    return { store, /* persistor */ }
  }
}
  